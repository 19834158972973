import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import { UncontrolledCollapse, Button } from "reactstrap"

import "../../components/prizes.css"

import { StaticImage } from "gatsby-plugin-image"

const GroundBreak = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s mobile-h3 display-4">
                American-Made Challenges
              </h3>
              <h2 className="h2s mobile-h2">Groundbreaking Hydro Prize</h2>
              <p
                className="page-hero-subheading mobile-text lead text-left"
                style={{ textAlign: "left" }}
              >
                Help support the foundations that enable tomorrow’s hydropower
                development!{" "}
              </p>
              <p className="mobile-text lead" style={{ textAlign: "justify" }}>
                The U.S. Department of Energy (DOE) Water Power Technologies
                Office (WPTO) launched the Groundbreaking Hydro Prize to
                encourage the development of new ideas to cut the costs,
                timelines, and risks associated with hydropower development.
                This competition challenged innovators to come up with new
                solutions to support hydropower project development by starting
                at square one—the foundation.
              </p>
              <p className="mobile-text lead" style={{ textAlign: "justify" }}>
                In April 2021, two teams were selected as winners to share a
                cash pool of $75,000.
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/groundbreakinghydro">
                    <button
                      type="button"
                      className="btns lead mt-5 border"
                      style={{ fontSize: "16px" }}
                    >
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage
            src="../../images/stick.webp"
            className="stick"
            alt="stick"
          />
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <div>
            <StaticImage
              src="../../images/Groundbreaking_Hydro_dam_graphic.jpg"
              className="img-styl"
              alt="hydro dam graphic"
              loading="lazy"
            />
          </div>
          <span style={{ padding: "5px" }}></span>
          <p className="pcol-fz">
            Like roots to a tree, geotechnical foundations provide structural
            support to a hydropower facility, and must be developed to ensure
            stability, safety, and performance for decades. While foundations
            are central to dam safety, challenges in their design and
            construction can lead to major project delays and cost overruns,
            potentially jeopardizing a project’s success.
          </p>
          <span style={{ padding: "5px" }}></span>
          <p className="pcol-fz">
            Through the Groundbreaking Hydro Prize, WPTO solicited solutions to
            address key challenges faced in one or more of the three foundation
            development phases:
          </p>
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>
              <b>Geotechnical Site Assessment: </b>
              Activities performed to obtain information needed to design and
              construct a hydropower foundation system
            </li>
            <li>
              <b>Foundation Design: </b>
              Process of using information from the site assessment to perform
              analyses and develop a cost-effective foundation system that meets
              the project design criteria
            </li>
            <li>
              <b>Foundation Construction: </b>
              Activities performed by the contractor, from mobilization through
              project commissioning, to fully develop the foundation system
            </li>
          </ul>
          <p className="pcol-fz">
            Targeting the most promising opportunities for future hydropower
            growth, the prize sought solutions based on:
          </p>
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>Low head (less than 30 feet)</li>
            <li>
              New stream-reach development (NSD): Sites defined as stream
              segments without hydroelectric facilities or other hydraulic
              structures
            </li>
            <li>Applicability to soil foundations.</li>
          </ul>
          <p className="pcol-fz">
            Please note that superstructures, including dams, were excluded from
            the prize scope.
          </p>
        </div>

        <div className="container">
          <h1 className="mac_h1">Goals of the Prize</h1>
          <hr />
          <p className="pcol-fz">Groundbreaking Hydro was designed to:</p>
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>Reduce construction costs</li>
            <li>Shorten overall installation times</li>
            <li>Minimize ground excavation</li>
            <li>
              Avoid disturbances in river connectivity during installation,
              operation, and maintenance (e.g., not use cofferdams when
              possible)
            </li>
            <li>Leverage advanced materials and techniques</li>
            <li>Prevent foundation treatment failures</li>
          </ul>
          <span style={{ padding: "5px" }}></span>
          <h1 className="mac_h1">Congratulations to Our Winners!</h1>
          <hr />
          <p className="customFontSize">
            Two teams, were chosen as winners of the Groundbreaking Hydro Prize
            and will share a cash prize pool of $75,000.
          </p>
          <div className="winners-wraps">
            <table class="table is-hoverable is-fullwidth set-table is-size-6 has-bg-light-blue">
              <thead>
                <tr>
                  <th className="d-mobile-none">Prize</th>
                  <th>Entry Title</th>
                  <th className="tds d-mobile2-none">Team</th>
                  <th class="has-text-centered">Video</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="d-mobile-none">
                    <b>Groundbreaking Prize</b>
                  </td>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34751"
                      class="has-text-bright"
                    >
                      Terra-Modulor
                    </a>
                  </td>
                  <td className="tds d-mobile2-none">
                    GZA GeoEnvironmental Inc and Littoral Power Systems
                  </td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="togg"
                      // style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt2">
                  <UncontrolledCollapse toggler="#togg">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/PGiN1Go-lmM?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td className="d-mobile-none">
                    <b>Innovator Prize</b>
                  </td>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34425"
                      class="has-text-bright"
                    >
                      WaterJet Drill with a Deep Array of Anchor Cables Concept{" "}
                    </a>
                  </td>
                  <td className="tds d-mobile2-none">Team Chemventive</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="togg2"
                      // style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt2">
                  <UncontrolledCollapse toggler="#togg2">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/vrYbr5jMVlU?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </tbody>
            </table>
            <p>
              <a
                href="https://www.herox.com/GroundbreakingHydro/teams"
                target="_blank"
              >
                <button type="button" class="btns my-5">
                  View Winners on HeroX
                </button>
              </a>
            </p>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Even more Groundbreaking Hydro Innovation</h1>
          <hr />
          <p className="pcol-fz">
            While these solutions weren’t chosen, we applaud the vision that so
            many teams put forth during the prize. Learn more about these teams
            and their ideas below.
          </p>
          <div className="table-wrap-ghi">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    Entry Title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th className="tds">Team</th>
                  <th class="has-text-centered">Video</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34761"
                      class="has-text-default"
                    >
                      ColdFeat Adaptive Hydropower Foundations
                    </a>
                  </td>
                  <td className="tds">Clay Koplin</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/ZudMGEChveM?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34564"
                      class="has-text-default"
                    >
                      Dam Cost Reduction Solution
                    </a>
                  </td>
                  <td className="tds">Brian Kalet</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t2"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t2">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/qVjBED9UPh8?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34483"
                      class="has-text-default"
                    >
                      ECC Bendable Concreate with sugarcane bagasse ash
                    </a>
                  </td>
                  <td className="tds">Estinamir</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t3"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t3">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/ZWKVAHUxysE?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34576"
                      class="has-text-default"
                    >
                      Floating Drum Turbine
                    </a>
                  </td>
                  <td className="tds">Shahriar Najafian</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t4"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t4">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/bXjd4-57LWk?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34749"
                      class="has-text-default"
                    >
                      Full Metal Beavers- Team 1
                    </a>
                  </td>
                  <td className="tds">Abtahee Safeen</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t5"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t5">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/ySAOrO2sEl8?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/33458"
                      class="has-text-default"
                    >
                      Gravity water flow water turbines for electricity
                    </a>
                  </td>
                  <td className="tds">Wasif's&nbsp;Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t6"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t6">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/B3XFwqBZ08g?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/32916"
                      class="has-text-default"
                    >
                      GVvFES - Swirling Solutions
                    </a>
                  </td>
                  <td className="tds">Ron Bingaman's Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t7"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t7">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/b8SWWiytxvg?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34730"
                      class="has-text-default"
                    >
                      Hydraulic Capsules and Slurry for Dam Foundations
                    </a>
                  </td>
                  <td className="tds">Baha Abulnaga Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t8"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t8">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/UG25PrY6lfs?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34748"
                      class="has-text-default"
                    >
                      Hydraulics
                    </a>
                  </td>
                  <td className="tds">Kelly Moti's Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t9"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t9">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/Vs_uWeH0qcc?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34745"
                      class="has-text-default"
                    >
                      Hydroelectric Generating System
                    </a>
                  </td>
                  <td className="tds">Gene Kasten</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t10"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t10">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/-Av4R9EPfZY?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34740"
                      class="has-text-default"
                    >
                      Kat-Osci foundation design for Low Head Layout
                    </a>
                  </td>
                  <td className="tds">Zlaya's Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t11"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t11">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/o_DB5YzwWlg?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34099"
                      class="has-text-default"
                    >
                      Let fish go upstream and return to their hometown
                    </a>
                  </td>
                  <td className="tds">Baomin Shen</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t12"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t12">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/H7j0eDtMKM4?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34628"
                      class="has-text-default"
                    >
                      Metabolic Studio LA Noria Project
                    </a>
                  </td>
                  <td className="tds">Cesar Estrada's Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t13"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t13">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/YxeR6ajpuvA?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34722"
                      class="has-text-default"
                    >
                      Modular Foundation-less Plug and Play Hydro Plant
                    </a>
                  </td>
                  <td className="tds">Guru Rathod</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t14"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t14">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/anzI95yB_8U?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34747"
                      class="has-text-default"
                    >
                      Modular Hydro Plant without Dam on River
                    </a>
                  </td>
                  <td className="tds">Guru Rathod</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t15"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t15">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/OAWJTa1YqyA?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34737"
                      class="has-text-default"
                    >
                      NuSTREEM's NuContainer Saves Time and Money
                    </a>
                  </td>
                  <td className="tds">NuSTREEM's Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t16"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t16">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/DRb1fMDJSs8?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34122"
                      class="has-text-default"
                    >
                      OFF SHORE HYDRO ENERGY &amp; Desalination SYSTEMS
                    </a>
                  </td>
                  <td className="tds">Walter Glover</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t17"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t17">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/DRb1fMDJSs8?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34293"
                      class="has-text-default"
                    >
                      Portable desalination plant
                    </a>
                  </td>
                  <td className="tds">MOSTAFA DAMAVANDI</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t18"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t18">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/xCi4oE5vZXg?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34703"
                      class="has-text-default"
                    >
                      Sensing Technology Reduce Construction Time/Cost
                    </a>
                  </td>
                  <td className="tds">Remote Sensing Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t19"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t19">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/1SUkZtYxqtk?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34712"
                      class="has-text-default"
                    >
                      Small Hydro Risk Reduction Method
                    </a>
                  </td>
                  <td className="tds">Gary Boring</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t20"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t20">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/aTqjrMmlSl0?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34729"
                      class="has-text-default"
                    >
                      Sustainable utility scale hydropower without dams
                    </a>
                  </td>
                  <td className="tds">Joseph Santos's&nbsp;Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t21"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t21">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/3wbaadhMeoY?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34731"
                      class="has-text-default"
                    >
                      Tidal wave power and energy storage
                    </a>
                  </td>
                  <td className="tds">Glenn Haines's Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t22"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t22">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/0bSNS5pEQ34?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/34704"
                      class="has-text-default"
                    >
                      Titan Hydroelectric System
                    </a>
                  </td>
                  <td className="tds">Rick Navarro's Team</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t23"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t23">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/eGG8FjrMuCg?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/GroundbreakingHydro/round/758/entry/33589"
                      class="has-text-default"
                    >
                      WEG generates hydro-electricity for Windsor Castle
                    </a>
                  </td>
                  <td className="tds">Jack Kurz</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="t24"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt3">
                  <UncontrolledCollapse toggler="#t24">
                    <div className="d-flex iframe-full">
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/_zhTfIZEcxU?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr></tr>
              </tbody>
            </table>
          </div>
          <hr />
        </div>

        <span style={{ padding: "5px" }}></span>

        <div className="container">
          <h1 className="mac_h1">Important Dates</h1>
          <hr />
          <p className="pcol-fz">
            <b>Final Dates are forthcoming and subject to change.</b>
          </p>
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>
              <b>Prize opens: </b>
              September 25, 2020
            </li>
            <li>
              <b>Prize closes:</b>
              January 31, 2021
            </li>
            <li>
              <b>Winners Announcement:</b>
              April 2021
            </li>
          </ul>
          <p>
            Sign up for updates on our{" "}
            <a href="https://www.herox.com/groundbreakinghydro">
              HeroX challenge page
            </a>
            .
          </p>
          <span style={{ padding: "5px" }}></span>
          <div>
            <p className="pcol-fz">
              Innovators, visionaries, or enthusiasts from across a variety of
              industries are encouraged to join the Groundbreaking Hydro Prize
              community and find like-minded collaborators. Help us build a
              better base from which tomorrow’s hydropower can thrive. Contact
              groundbreaking.hydro@nrel.gov with your industry feedback,
              questions and ideas!
            </p>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1 mb-4">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <div>
            <StaticImage
              src="../../images/groundbreak.jpg"
              className="img-styl"
              alt="groundbreak"
              loading="lazy"
            />
          </div>
          <p className="hps mt-4">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default GroundBreak
